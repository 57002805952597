/* eslint-disable import/order */
// 22px size
import Connect from "assets/brand/icons/22-connect.svg";
import DownArrow from "assets/brand/icons/22-down-arrow.svg";
import Dribbble from "assets/brand/icons/22-dribbble.svg";
import Facebook from "assets/brand/icons/22-facebook.svg";
import Github from "assets/brand/icons/22-github.svg";
import Grid from "assets/brand/icons/22-grid.svg";
import Instagram from "assets/brand/icons/22-instagram.svg";
import LeftArrow from "assets/brand/icons/22-left-arrow.svg";
import LinkChain from "assets/brand/icons/22-link.svg";
import Linkedin from "assets/brand/icons/22-linkedin.svg";
import MapPin from "assets/brand/icons/22-map-pin.svg";
import Medium from "assets/brand/icons/22-medium.svg";
import Phone from "assets/brand/icons/22-phone.svg";
import RightArrow from "assets/brand/icons/22-right-arrow.svg";
import Spotify from "assets/brand/icons/22-spotify.svg";
import Twitter from "assets/brand/icons/22-twitter.svg";

// 24px size
import Checkmark from "assets/brand/icons/24-checkmark.svg";

// 40px size
import Discovery from "assets/brand/icons/40-discovery.svg";
import Summary from "assets/brand/icons/40-summary.svg";

// 72px size
import AnniversaryGifts from "assets/brand/icons/72-anniversary-gifts.svg";
import AppleWatch from "assets/brand/icons/72-apple-watch.svg";
import Backend from "assets/brand/icons/72-backend.svg";
import BrandBook from "assets/brand/icons/72-brand-book.svg";
import Camp from "assets/brand/icons/72-camp.svg";
import Cash from "assets/brand/icons/72-cash.svg";
import Celebration from "assets/brand/icons/72-celebration.svg";
import Client from "assets/brand/icons/72-client.svg";
import CMS from "assets/brand/icons/72-cms.svg";
import DataAndAnalytics from "assets/brand/icons/72-data-and-analytics.svg";
import Dapp from "assets/brand/icons/72-dapp.svg";
import Design from "assets/brand/icons/72-design.svg";
import Dove from "assets/brand/icons/72-dove.svg";
import Handshake from "assets/brand/icons/72-handshake.svg";
import HealthBenefits from "assets/brand/icons/72-health-benefits.svg";
import Heart from "assets/brand/icons/72-heart.svg";
import Idea from "assets/brand/icons/72-idea.svg";
import Implementation from "assets/brand/icons/72-implementation.svg";
import Laptop from "assets/brand/icons/72-laptop.svg";
import Launch from "assets/brand/icons/72-launch.svg";
import Lightbulb from "assets/brand/icons/72-lightbulb.svg";
import MarkedCalendar from "assets/brand/icons/72-marked-calendar.svg";
import Marketing from "assets/brand/icons/72-marketing.svg";
import Mobile from "assets/brand/icons/72-mobile.svg";
import Monetization from "assets/brand/icons/72-monetization.svg";
import NavigationLeft from "assets/brand/icons/72-navigation-left.svg";
import NavigationRight from "assets/brand/icons/72-navigation-right.svg";
import Pacifier from "assets/brand/icons/72-pacifier.svg";
import PerformanceBonus from "assets/brand/icons/72-performance-bonus.svg";
import Photography from "assets/brand/icons/72-photography.svg";
import Plane from "assets/brand/icons/72-plane.svg";
import Plant from "assets/brand/icons/72-plant.svg";
import ProjectManagement from "assets/brand/icons/72-project-management.svg";
import Retirement from "assets/brand/icons/72-retirement.svg";
import Shoutout from "assets/brand/icons/72-shoutout.svg";
import Stethoscope from "assets/brand/icons/72-stethoscope.svg";
import Storytelling from "assets/brand/icons/72-storytelling.svg";
import Strategy from "assets/brand/icons/72-strategy.svg";
import Travel from "assets/brand/icons/72-travel.svg";
import Web from "assets/brand/icons/72-web.svg";

// Values
import ValuesAllAround from "assets/brand/careers/values/awards/all-around.svg";
import ValuesCollaborativeAward from "assets/brand/careers/values/awards/collaborative.svg";
import ValuesIntegrityAward from "assets/brand/careers/values/awards/integrity.svg";
import ValuesPlayfulAward from "assets/brand/careers/values/awards/playful.svg";
import ValuesProgressiveAward from "assets/brand/careers/values/awards/progressive.svg";
import ValuesTenaciousAward from "assets/brand/careers/values/awards/tenacious.svg";
import ValuesCollaborative from "assets/brand/careers/values/collaborative.svg";
import ValuesIconsLineup from "assets/brand/careers/values/icons-lineup.svg";
import ValuesIntegrity from "assets/brand/careers/values/integrity.svg";
import ValuesPlayful from "assets/brand/careers/values/playful.svg";
import ValuesProgressive from "assets/brand/careers/values/progressive.svg";
import ValuesTenacious from "assets/brand/careers/values/tenacious.svg";

// Options defined as a type for intellisense
export type IconSlugType =
  // 22px
  | "connect"
  | "down-arrow"
  | "dribbble"
  | "facebook"
  | "github"
  | "grid"
  | "instagram"
  | "left-arrow"
  | "link"
  | "linkedin"
  | "map-pin"
  | "medium"
  | "phone"
  | "right-arrow"
  | "spotify"
  | "twitter"
  // 24px
  | "checkmark"
  // 72px
  | "anniversary-gifts"
  | "apple-watch"
  | "backend"
  | "brand-book"
  | "camp"
  | "cash"
  | "celebration"
  | "client"
  | "cms"
  | "dapp"
  | "data-and-analytics"
  | "design"
  | "discovery"
  | "dove"
  | "handshake"
  | "health-benefits"
  | "heart"
  | "idea"
  | "implementation"
  | "laptop"
  | "launch"
  | "lightbulb"
  | "marked-calendar"
  | "marketing"
  | "mobile"
  | "monetization"
  | "navigation-left"
  | "navigation-right"
  | "pacifier"
  | "performance-bonus"
  | "photography"
  | "plane"
  | "plant"
  | "project-management"
  | "retirement"
  | "shoutout"
  | "stethoscope"
  | "storytelling"
  | "strategy"
  | "summary"
  | "travel"
  | "web"
  // Values Page Icons
  | "values-icons-lineup"
  | "values-collaborative"
  | "values-integrity"
  | "values-playful"
  | "values-progressive"
  | "values-tenacious"
  | "values-collaborative-award"
  | "values-integrity-award"
  | "values-playful-award"
  | "values-progressive-award"
  | "values-tenacious-award"
  | "values-all-around-award";

interface IconProps {
  slug: IconSlugType;
  className?: string;
  id?: string;
}

const Icon = ({ slug, className, id, ...restProps }: IconProps) => {
  // if there is no className we must apply a width and height otherwise the icon does not show
  const props = { className: className ? className : "h-full w-full", id, ...restProps };
  switch (slug) {
    case "checkmark":
      return <Checkmark {...props} />;
    case "connect":
      return <Connect {...props} />;
    case "down-arrow":
      return <DownArrow {...props} />;
    case "dribbble":
      return <Dribbble {...props} />;
    case "left-arrow":
      return <LeftArrow {...props} />;
    case "link":
      return <LinkChain {...props} />;
    case "linkedin":
      return <Linkedin {...props} />;
    case "right-arrow":
      return <RightArrow {...props} />;
    case "anniversary-gifts":
      return <AnniversaryGifts {...props} />;
    case "apple-watch":
      return <AppleWatch {...props} />;
    case "backend":
      return <Backend {...props} />;
    case "brand-book":
      return <BrandBook {...props} />;
    case "camp":
      return <Camp {...props} />;
    case "cash":
      return <Cash {...props} />;
    case "celebration":
      return <Celebration {...props} />;
    case "client":
      return <Client {...props} />;
    case "dapp":
      return <Dapp {...props} />;
    case "design":
      return <Design {...props} />;
    case "discovery":
      return <Discovery {...props} />;
    case "dove":
      return <Dove {...props} />;
    case "facebook":
      return <Facebook {...props} />;
    case "github":
      return <Github {...props} />;
    case "grid":
      return <Grid {...props} />;
    case "handshake":
      return <Handshake {...props} />;
    case "health-benefits":
      return <HealthBenefits {...props} />;
    case "heart":
      return <Heart {...props} />;
    case "idea":
      return <Idea {...props} />;
    case "instagram":
      return <Instagram {...props} />;
    case "implementation":
      return <Implementation {...props} />;
    case "laptop":
      return <Laptop {...props} />;
    case "launch":
      return <Launch {...props} />;
    case "lightbulb":
      return <Lightbulb {...props} />;
    case "navigation-left":
      return <NavigationLeft {...props} />;
    case "navigation-right":
      return <NavigationRight {...props} />;
    case "marked-calendar":
      return <MarkedCalendar {...props} />;
    case "marketing":
      return <Marketing {...props} />;
    case "map-pin":
      return <MapPin {...props} />;
    case "medium":
      return <Medium {...props} />;
    case "mobile":
      return <Mobile {...props} />;
    case "pacifier":
      return <Pacifier {...props} />;
    case "performance-bonus":
      return <PerformanceBonus {...props} />;
    case "phone":
      return <Phone {...props} />;
    case "photography":
      return <Photography {...props} />;
    case "plane":
      return <Plane {...props} />;
    case "plant":
      return <Plant {...props} />;
    case "project-management":
      return <ProjectManagement {...props} />;
    case "retirement":
      return <Retirement {...props} />;
    case "cms":
      return <CMS {...props} />;
    case "data-and-analytics":
      return <DataAndAnalytics {...props} />;
    case "monetization":
      return <Monetization {...props} />;
    case "shoutout":
      return <Shoutout {...props} />;
    case "spotify":
      return <Spotify {...props} />;
    case "stethoscope":
      return <Stethoscope {...props} />;
    case "storytelling":
      return <Storytelling {...props} />;
    case "strategy":
      return <Strategy {...props} />;
    case "summary":
      return <Summary {...props} />;
    case "travel":
      return <Travel {...props} />;
    case "twitter":
      return <Twitter {...props} />;
    case "web":
      return <Web {...props} />;
    case "values-icons-lineup":
      return <ValuesIconsLineup {...props} />;
    case "values-collaborative":
      return <ValuesCollaborative {...props} />;
    case "values-integrity":
      return <ValuesIntegrity {...props} />;
    case "values-playful":
      return <ValuesPlayful {...props} />;
    case "values-progressive":
      return <ValuesProgressive {...props} />;
    case "values-tenacious":
      return <ValuesTenacious {...props} />;
    case "values-collaborative-award":
      return <ValuesCollaborativeAward {...props} />;
    case "values-integrity-award":
      return <ValuesIntegrityAward {...props} />;
    case "values-playful-award":
      return <ValuesPlayfulAward {...props} />;
    case "values-progressive-award":
      return <ValuesProgressiveAward {...props} />;
    case "values-tenacious-award":
      return <ValuesTenaciousAward {...props} />;
    case "values-all-around-award":
      return <ValuesAllAround {...props} />;
    default:
      return <h6>icon slug unknown</h6>;
  }
};

export default Icon;
